/* eslint-disable-next-line no-useless-escape */
<template>
  <div>
    <el-tree
      :data="tree"
      :props="defaultProps"
      @node-click="handleNodeClick"
      :default-expand-all="true"
      :expand-on-click-node="false"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        {{ data.data.name }}{{ node.label }}
        <el-button
          type="text"
          size="mini"
          style="margin-left: 10px"
          @click="addProject(data)"
          v-if="data.data.level === 'C'"
        >
          <i class="el-icon-plus"></i>新建项目
        </el-button>
        <el-button
          type="text"
          size="mini"
          style="margin-left: 10px"
          @click="addDepart(data)"
          v-if="data.data.level === 'E'"
        >
          <i class="el-icon-plus"></i>新建部门
        </el-button>
        <el-button
          type="text"
          size="mini"
          style="margin-left: 10px"
          @click="addPosition(data)"
          v-if="data.data.level === 'D'"
        >
          <i class="el-icon-plus"></i>新建职位
        </el-button>
      </span>
    </el-tree>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <el-input type="text" v-model="name" :placeholder="placeholder"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const orgList = api()('org.list.json');
export const departmentAdd = api()('department.add.json');
export const positionAdd = api()('position.add.json');
export const orgEdit = api()('org.edit.json');
export const orgDel = api()('org.del.json');
export default {
  data () {
    return {
      tree: [],
      defaultProps: {
        children: 'children',
        label: 'data.name',
      },
      dialogVisible: false,
      title: '',
      placeholder: '',
      parentCode: '',
      name: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    handleNodeClick () { },
    async getList () {
      const res = await orgList()
      this.tree = this.dataParse(res.map(it => ({ ...this.parsePath(it.path), name: it.name })))
      console.log(this.tree);
    },
    parsePath (path = '') {
      const result = {
        code: path.match(/(?<=::)[^/.]+$/g)?.toString(),

        path: path,

        C: path.match(/(?<=C::)[^/.]+/g)?.toString(),

        E: path.match(/(?<=E::)[^/.]+/g)?.toString(),

        D: path.match(/(?<=D::)[^/.]+/g)?.toString(),

        P: path.match(/(?<=P::)[^/.]+/g)?.toString(),

        cpath: path.match(/.+\/C::[^/.]+/g)?.toString(),

        epath: path.match(/.+\/E::[^/.]+/g)?.toString(),

        dpath: path.match(/.+\/D::[^/.]+/g)?.toString(),

        ppath: path.match(/.+\/P::[^/.]+/g)?.toString(),

        level: undefined,

      }

      result.level = result.P ? 'P' : result.D ? 'D' : result.E ? 'E' : result.C ? 'C' : 'R'

      return result

    },
    dataParse (data) {
      const tree = [];
      for (let index = 0; index < data.length; index++) {
        let children = tree;
        const item = data[index];
        const paths = [];
        item.path.split('/').reduce((pv, cv) => {
          pv.push(cv);
          const fullpath = pv.join('/');
          const virtual = fullpath != item.path;
          paths.push({
            label: cv,
            fullpath: fullpath,
            virtual: virtual,
            data: virtual ? { resourceType: '0' } : item,
            children: []
          });
          return pv;
        }, []);
        for (let index = 0; index < paths.length; index++) {
          const item = paths[index];
          const tar = children.find(it => it.label === item.label);
          if (tar) {
            children = tar.children;
            if (!item.virtual) {
              tar.data = item.data;
            }
            tar.virtual = tar.virtual && item.virtual;
          } else {
            children.push(item);
            children = item.children;
          }
        }
      }
      return tree;
    },
    addProject (data) {
      this.$router.push({
        path: '/system/project/add',
        query: {
          code: data.code,
        }
      })
    },
    addDepart (data) {
      this.dialogVisible = true
      this.title = '新建部门'
      this.placeholder = '请输入部门名称'
      this.parentCode = data.data.code

    },
    addPosition (data) {
      this.dialogVisible = true
      this.title = '新建职位'
      this.placeholder = '请输入职位名称'
      this.parentCode = data.data.code
    },
    async save () {
      if (this.title === '新建部门') {
        await departmentAdd({
          parentCode: this.parentCode,
          name: this.name,
        })
        this.$message.success('部门创建成功！')
      }
      if (this.title === '新建职位') {
        await positionAdd({
          parentCode: this.parentCode,
          name: this.name,
        })
        this.$message.success('职位创建成功！')
      }
      this.name = ''
      this.dialogVisible = false
      this.getList()
    },
  }
}
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>